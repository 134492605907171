//color 
@each $color, $value in $colors {
  .c#{$color} {
    color: $value;
  }
}

//backgroundcolor 
@each $color, $value in $colors {
  .bg#{$color} {
    background-color: $value;
  }
}


