
.btn {
  display: inline-block; 
  background-color: $transparent;
  color: $white;
  border: 1px solid $transparent;
  border-radius: $mb-0-25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding:$mb-0-5  calc($mb-0-75 + $mb-0-5);
  font-size: 14px;
  font-weight: $font-semi-bold;
  line-height: $mb-1-5;
  text-decoration: none;

  &:focus {
    outline: 0;
    box-shadow:0 0 0 0.2rem $transparent;
  }

  &:disabled,
  &.disabled {
    opacity: .65;
  }
}

@mixin button-color-variant($b_color, $bg_color, $brd_color) {
  color: $b_color;
  background-color: $bg_color;
  border-color: $brd_color;
}

@mixin button-color-border($b_color, $bg_color, $brd_color) {
  color: $b_color;
  background-color: $bg_color;
  border-color: $brd_color;
}




//disabling of clicks on `<a>` elements
a.btn.disabled {
  pointer-events: none;
}

//
// button full width
//

.btn__full--width {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $mb-1;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn__full--width {
    width: 100%;
  }
}

//
//  buttons color
//

$button-colors: ("pink":#fe4987);

@each $color, $value in $button-colors {
  .btn-#{$color} {
    @include button-color-variant($white, $value,$transparent);//color,bgcolor,bordercolor
  }
}

$button-border-colors: ("gray":#9D9C9C);

@each $color, $value in $button-border-colors {
  .btn-outline-#{$color} {
    @include button-color-border($value, $transparent,$value);//color,bgcolor,bordercolor
  }
}

// new order btn
.btn__round {
  border-radius: 30px;
  height: 40px;
  width: 41px;
  padding: 10px;
  background-color: #F3F3F3;
  color: #9D9C9C;
}