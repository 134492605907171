.otp--title {
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1rem;
}

.otp__mobile_no--verify {
  font-size: 0.813rem;
  font-weight: 500;
  padding: 0.5rem 0;
}

.resend--button {
  font-size: 0.813rem;
  font-weight: 600;
  color: #fe4987;
  padding: 0.5rem 0 1rem 0;
  text-align: center;
}

.input__text--style {
  text-align: center;
  letter-spacing: 3px!important;
  font-size: 1.70rem!important;
}

@media screen and (max-width: 600px) {
  .w50,.w70 {
    width: 100%!important;
  }
}
.success_animation {
  height: 4rem;
  width: 4rem;
  background-color: #41A49F;
  border-radius: 50%;
  margin: auto;
  padding: 5% 0;
  text-align: center;
}

.animated-check {
  height: 2em;
  width: 4em;
}

.animated-check path {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 1s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.verify-time {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
