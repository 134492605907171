// border-radius
$border-radius:(.25rem,.2rem,.3rem,.60rem);
$border-utilities:(
  'border','border-top','border-bottom','border-left','border-right');

@each $size in $border-radius {
  $i: index($border-radius, $size);
  .border-#{$i}{
    border-radius: $size;
  }
}

@each $value in $border-utilities {
  .#{$value}{
    #{$value}: 1px solid #CCCCCC;
  }
}

@each $value in $border-utilities {
  .#{$value}-sperator{
    #{$value}: 1px solid #cbcaca;
  }
}