//font size
$font-sizes: ('h1':2rem,'h2':1.5rem,'h3':1.25rem,'h4':1.125rem,'h5':.938rem,'h6':.813rem);

@each $size, $value in $font-sizes {
  .#{$size} {
   font-size: $value;
  }
}

//font weight
$font-weights:(
  'w100':100,
  'w200':200,
  'w300':300,
  'w400':400,
  'w500':500,
  'w600':600,
  'w700':700,
  'w800':800,
  'w900':900,
  'bold':bold,
  'bolder':bolder
);

@each $size, $fon-weight-tvalue in $font-weights {
  .f#{$size} {
   font-weight: $fon-weight-tvalue;
  }
}
