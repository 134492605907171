//
// Color system
//
$dark:                  #000 !default;
$white:                 #fff !default;
$input-border-color: #ced4da !default;
$container-color:    #DEF1F2 !default;
$cyan-dark:          #41A49F !default;
$title-color:        #fe4987 !default;
$transparent:      transparent !default;
$disabled:           #CCCCCC !default;
$dark-grey:          #757575 !default;
$link:               #3D51B4 !default;
$red:                #ff0000 !default;

$colors: ()!default;
$colors: map-merge(
  ( 
    "black":        $dark,
    "white":        $white,
    "cyan-25":      $container-color,
    "cyan-100":     $cyan-dark,
    "gray":         $input-border-color,
    "gray-75":      $dark-grey,
    "pink":         $title-color,
    "transparent":  $transparent,
    "gray-25":      $disabled,
    "blue":         $link,
    "danger":       $red
  ),
  $colors
);

/* ======== Font and typography ======== */
$body-font: "Roboto", Arial, sans-serif;


$big-font-size: 2rem;        //2rem = 32px;
$h1-font-size: 1.5rem;       //1.5rem = 24px
$h2-font-size: 1.25rem;      //1.25rem = 20px
$h3-font-size: 1.125rem;     //1.125rem = 18px
$normal-font-size: .938rem;  //.938rem = 15.008px
$small-font-size: .813rem;   //.813rem = 13.008px
$smaller-font-size: .75rem;  //.75rem = 12px

/* ======== Font weight ======== */
$font-medium: 500;
$font-semi-bold: 600;

/* ======== Margenes Bottom ======== */
/* .25rem = 4px, .5rem = 8px, .75rem = 12px ...  */
$mb-0-25: .25rem;
$mb-0-5: .5rem;
$mb-0-75: .75rem;
$mb-1: 1rem;
$mb-1-5: 1.5rem;
$mb-2: 2rem;
$mb-2-5: 2.5rem;
$mb-3: 3rem;

/* ======== z index ======== */
$z-tooltip: 10;
$z-fixed: 100;
$z-modal: 1000;
