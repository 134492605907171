
.registration__info--title {
  font-size: 2.19rem;
}
.registration__info--subtitle{
  font-size: 20px;
}

.regis_tc{
  font-size: 0.75rem;
}

.registration__form--title {
  font-size: 0.938rem;
  font-weight: 600;
  text-align: center;
  padding:0 0 1rem 0;
}

.gap {
  padding: 2rem;
}

.regis__form--border_top {
  border-top: 4px solid #fe4987;
}

.checkbox__declarations {
  accent-color: #fe4987;
  height: 15px;
  width: 15px;
  margin-right: 0.5rem;
  vertical-align: middle;
}

@media screen and (max-width: 600px) {
  .gap {
    padding: 1rem 0;
  }
  .form {
    padding: 1rem;
  }
  .registration__info--title {
    font-size: 1.75rem;
    padding-top: 3rem;
  }
  .registration__info--subtitle{
    font-size: 20px;
  }
}
