@import "../scss/variable";

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 62px;
  width: 100%;
  background-color: #3f51b5;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  border-right: 1px solid #6b79c6;

  img {
    height: 47px;
    padding: 4px;
    margin: 0 2rem;
  }
}

.nav--links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding:0;
}

.navigation-menu {
  width: 100%;
    a {
      text-decoration: none;
      margin: 0 1.5rem;
      display: block;
      width: auto;
      color: $white;
      padding: 1.1rem 0;
    }
}

.active {
  border-bottom: 3px solid $title-color;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #3f51b5;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  display: none;
}

@media screen and (max-width: 800px) {
  .hamburger {
    display: block;
  }

  .mobile--bg {
    position: fixed;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
  }

  .mobile__logo--section {
    height: 62px;
  }
  
  .mobile__nav--img {
   height: 32px;
  }

  .close-menu {
    position: relative;
    left: 65%;
    top: 3px;
    padding: 10px;
    color: #fff;
    z-index: 2;
  }
  
  .close-menu svg {
    height: 23px;
  }

  .brand-name {
    border-right: none;

    img {
      height: 40px;
      padding: 4px;
      margin: 0 2.75rem;
    }
  }

  .navigation-menu div {
    flex-direction: column;
    position: fixed;
    top: 0px;
    left: 0;
    width: 65%;
    height: 100%;
    background-color: $white;
    display: none;
    z-index: 2;
    transition: 0.5s!important;
    overflow-x: hidden;
  }

  .navigation-menu {

    a {
      color: $dark;
      width: 100%;
      padding: 1rem;
      text-align: left;
      margin: 0;
    }
    .active{
      border-bottom: 0px solid $title-color;
    }
  }

  .navigation-menu.expanded div {
    display: block;
    transition: all 0.25s;
  }
}
