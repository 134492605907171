.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  .modal-content {
    width: 300px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-150px);
    border-radius: 20px;
    text-align: center;

      .success_animation {
        height: 4rem;
        width: 4rem;
        background-color: #41A49F;
        border-radius: 50%;
        margin:3%  auto;
        padding: 5% 0;
        text-align: center;
      }

      .animated-check {
        height: 2em;
        width: 4em;
      }

      .animated-check path {
        fill: none;
        stroke: #fff;
        stroke-width: 2px;
        stroke-dasharray: 23;
        stroke-dashoffset: 23;
        animation: draw 1s linear forwards;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      @keyframes draw {
        to {
          stroke-dashoffset: 0;
        }
      }

      .modal-body{
        padding:10px  0px;
      }

    .close--button {
      border: none;
      background-color: #fff;
      color: #9d9c9c;
      padding: 10px;
      margin-right: 21px;
      float: right;
    }
  }

}