.autocomplete {
  z-index: 1;
}

.new_oreder-form{
  width: 653px;
}

.autocomplete>ul {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  max-height: 184px;
  overflow-y: auto;
}

.giftCrad--name {
  padding: 0.375rem 0.75rem;
}

.rotate {
  transform: rotate(90deg);
  height: 18px;
  margin-top: 4px;
}

.searchIcon {
  height: 18px;
  margin-top: 4px;
}

.done,
.cancel {
  max-width: 46px;
}

.order {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 0.5fr;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 10px;
}

.edit,
.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 41px;
  background: #F3F3F3 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 1px solid transparent;
}

.edit {
  border-radius: 18px 0px 0px 18px;
  margin-right: 5px;
}

.delete {
  border-radius: 0px 18px 18px 0px;
}

.group-btn {
  display: flex;
  justify-content: center;
}

.amount-load {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control-none{
  border: none;
}

.form-control-none:focus-visible{
  outline: 0;
}

.rupees {
  background-image: url(http://i.imgur.com/Fd7NROx.png);
  padding-left: 14px;
  background-size: 10px 15px;
  background-repeat: no-repeat;
  background-position: 1px 6px;
}

.rupees_show{
  @extend .rupees;
  background-position: 8px 11px;
}

.order-value {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .order-price{

    .order-price_amount{
      margin-top: -5px;
      font-size: 25px;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 600px) {
  .new_order{
    padding: 1rem!important;
  }
  
  .w40,
  .new_oreder-form {
    width: 100%!important;
    padding-inline: 10px;
  }

  .order,.amount-load {
    grid-template-columns: 1fr;
  }

  .order-value {
    grid-template-columns: 1fr;
    text-align: center;
    
    .ml5{
      margin-left: 0px;
    }

    div{
      align-items: center;
      justify-content: center;
    }
  }

  .group-btn {
    display: flex;
    justify-content: left;
  }
}