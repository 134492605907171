@import "../scss/variable";

.wallet--icon {
  color: $cyan-dark;
  height: 76px;
  width: 76px;
  border-radius: 50%;
  border: 1px solid $cyan-dark;
  margin: -74px auto auto;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
}

.user {
  padding: $smaller-font-size 0 $smaller-font-size 0;
  font-size: $normal-font-size;
}

.message {
  color: $title-color;
  line-height: 1.2;
  padding: $mb-0-25 0 $h3-font-size 0;
}


.cart_space {
  padding: $normal-font-size 0;
}

.cart_space i {
  font-size: 5.5rem;
  color: $disabled;
}

.btn-group {
  width: 100%;
  display: flex;
  justify-content: center;
}

.slick-list {
  width: 100vw;
}

.slick-slide{
  width: 320px;
  margin-right: 10px;
}

.slide--img--center{
  background-image: url('https://res-1.cloudinary.com/linkisin/image/asset/f_auto,fl_lossy,q_auto/gc-ptrn-3cd5200da00cf6734122490c5b4170bd.png');
  background-blend-mode: overlay;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  background-color: #fff;
  box-shadow: 0px 2px 3px #00000017;
  border-radius: 10px;
  opacity: 1;
  margin: 0 0px 0 15px;
}

.swiper-wrapper:last-child{
  margin: 0 15px 0 0;
}

.slide--img{
  max-height: 55px;
  max-width: 110px;
}

.icon--style {
  max-width: 45%;
}

.order--status {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  box-shadow: 0px 2px 3px #00000017;
  margin-top: .75rem;
}

.order--details__status {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 1rem;
  border-top: 1px solid #cbcaca;
}

.order--details__shadow {
  box-shadow: 0px 2px 3px #00000017;
}

.btn-small {
  padding: 5px 10px;
}

.tooltip-shadow {
  border: 1px solid #ccc !important;
  border-radius: 6px !important;
  box-shadow: 0 5px 10px #00000033 !important;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between{
  justify-content: space-between;
}

.text-align{
  text-align: center;
}

.va-tb{
  vertical-align: text-bottom;
}

.swiperlr{
  width: 100%;
  max-width: 1360px;
  padding: 0 15px 0;
  margin: auto;
}


@media screen and (max-width: 800px) {
  .w80 {
    width: 100%!important;
  }
  .swiperlr{
    padding: 0px;
  }

  .w50 {
    width: 60%;
  }

  .order-title {
    font-size: 0.75rem;
  }

  .cart {
    margin-top: 0;
  }

  .h6 {
    font-size: .64rem;
  }
}

@media screen and (max-width: 600px) {
  .swiperlr{
    padding: 0px;
  }
  .w80,
  .w50 {
    width: 100%!important;
  }

  .h6 {
    font-size: 0.813rem;
  }

  .cart {
    margin-top: $h2-font-size;
  }

  .btn {
    font-size: 12px;
  }

  .wallet--icon {
    margin: -66px auto auto;
  }

  .order--status,
  .order--details__status {
    display: grid;
    grid-template-columns: 1fr;
  }

  .order--status>div,
  .order--details__status>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .order--status>div>div,
  .order--details__status>div>div {
    padding: 0.5rem;
  }
  
  .rupeessymbol{
    margin-left: -4px;
  }

  .rupeessymbolt{
    margin-left: -12px;
  }

  .m-none {
    display: none;
  }

  .btn-details {
    display: flow-root !important;
  }

  .border-top-sperator {
    border-top: none!important;
  }

  .store>button {
    width: 100%;
  }
  .text-align{
    text-align: left;
  }
}
