.title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting_title{
  text-align: center;
}

.setting__form--border_top {
  border-top: 4px solid #fe4987;
}

.setting__form--title {
  font-size: 0.938rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0;
}

.checkbox__declarations {
  accent-color: #fe4987;
  height: 15px;
  width: 15px;
  margin-right: 0.5rem;
  vertical-align: text-bottom;
}

@media screen and (max-width: 600px) {
  .w40 {
    width: 100%;
    padding-inline: 10px;
  }
  .setting_title{
    text-align: left;
  }
}