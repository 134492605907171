
body{
  font-family: 'Roboto', sans-serif;
  background-color: $container-color;
  font-size: 16px;
}

.body{
  background: linear-gradient(180deg, #DEF1F2 30%, #F7F7F7 30%);
  overflow-x: hidden;
}

//container for grid system
.Container{
  width: 100%;
  max-width: 1360px;
  padding: 0 15px 0;
  margin: auto;
}

.grid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap:1rem;
}

//form group for input and label wrap
.form-group {
  margin-bottom: 1rem;
}

// input type style
.form-control{
  display: block;
  width: 100%;
  height: calc(1.5rem + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $dark;
  background-color: $white;
  border: 1px solid $input-border-color;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

// form design
.form{
  background-color: $white;
  border-radius: $mb-0-5;
  padding:$mb-1 $mb-2 $mb-2;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);;
}

.text-decoration-none{
  text-decoration: none;
  color: inherit;
}

.bold {
  font-weight: 700;
}

.badge{
  display: inline-block;
  padding: 0.4em 0.6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-pill {
  padding-right: 1.25em;
  padding-left: 1rem;
  border-radius: 10rem;
}

.badge-pending{
  color: #fff;
  background-color:  #212121;
}

.badge-success{
  color: #fff;
  background-color: #28a745;
}

.badge-in-progress{
  color: #fff;
  background-color: #9D9C9C;
}

.cursor-hand{
  cursor: pointer;
}

input:read-only,textarea:read-only{
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  border-radius: 5px;
  opacity: 1;
}

input:read-only:focus-visible,textarea,:read-only:focus-visible{
  outline-width: 0;
}

@media screen and (max-width: 600px) {
  .grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap:0;
  }
}

