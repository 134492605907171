.login__form--border_top {
  border-top: 4px solid #fe4987;
}

.login__form--title {
  font-size: 0.938rem;
  font-weight: 600;
  text-align: center;
  padding:0 0 1rem 0;
}

.center-grid {
  display: grid;
  place-items: center;
}
.height-full{
  height: calc(100vh - 62px);
}

@media screen and (max-width: 600px) {
  .w70 {
    width: 100%;
    padding-inline: 10px;
  }
}