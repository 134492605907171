$margin-side:(
  m:margin,
  mt:margin-top,
  ml:margin-left,
  mb:margin-bottom,
  mr:margin-right
);
$margin-value:(
    1:.25rem,
    2:.5rem,
    3:.75rem,
    4:1rem,
    5:1.25rem,
  );

      @each $key,$side in $margin-side {
        @each $i,$values in $margin-value {
          .#{$key}#{$i}{
            #{$side}:#{$values};
          }
        }
      }

.m-auto{
  margin: auto;
}
