$padding-side:(
  p:padding,
  pt:padding-top,
  pl:padding-left,
  pb:padding-bottom,
  pr:padding-right
);
$padding-value:(
    1:.25rem,
    2:.5rem,
    3:.75rem,
    4:1rem,
    5:1.25rem,
  );

      @each $key,$side in $padding-side {
        @each $i,$values in $padding-value {
          .#{$key}#{$i}{
            #{$side}:#{$values};
          }
        }
      }



// //font size
