// text alignment 
$alignments:left,right,center;

@each $alignment in $alignments {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

// float left and right
.fl{
  float: left;
}

.fr{
  float: right;
}